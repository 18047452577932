/*variables*/

:root {
  --text-white: #ffffff;
  --text-black: #000000;
  --btn-danger: #bf1e2e;
  --icon-gray-color: #787676;
  --light-gray-color: #979797;
  --text-black-color: #231f20;
  --border-color: #a19fa0;
  --primary-btn-color: #bf1e2e;
  --main-body-bg: #f2f2f2;
  --gray-secondary-color: #d4d4d4;
}
/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 40px white inset !important;
}
.btn-dark {
  border: 1px solid var(--text-black);
  background-color: var(--text-black) !important;
  color: var(--text-white);
  opacity: 1;
}
.btn-dark:hover,
btn-dark:active,
btn-dark:focus {
  background-color: var(--text-black);
  color: var(--text-white);
  box-shadow: none;
}
.form-check-input:checked {
  background-color: var(--btn-danger);
  border-color: var(--btn-danger);
}
.form-check-input:focus {
  border-color: var(--btn-danger);
  outline: 0;
  /*box-shadow: 0 0 0 0.25rem rgb(191 30 46 / 25%);*/
  box-shadow: none;
}
.pd-right-30 {
  padding-right: 30px !important;
}
.mr-4 {
  margin-right: 4rem;
}
.cursor-pointer {
  cursor: pointer;
}
.emp-select-area {
  min-height: 68.4px;
}
.reg-select-area {
  min-height: 62.4px;
}
.emp-gender-mb {
  margin-bottom: 15px !important;
}
.showChildModal {
  background-color: rgba(0, 0, 0, 0.15);
}
.upload-file-section {
  margin-bottom: 44px;
}
.sidebar[data-color="black"]:after {
  background: #fff;
}
.sidebar[data-image]:after {
  opacity: 1;
}
.sidebar .nav li.active .nav-link,
body > .navbar-collapse .nav li.active .nav-link {
  color: var(--text-black-color);
}
.sidebar .nav li .nav-link,
body > .navbar-collapse .nav li .nav-link {
  color: var(--text-black-color);
}
.sidebar .sidebar-wrapper li.active {
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
}
.sidebar-checkout-img {
  position: absolute;
  bottom: 30px;
  right: 10px;
}
.sidebar-checkout-img img {
  width: 18px;
}
.sidebar .sidebar-wrapper .logo {
  position: relative;
}
.sidebar .sidebar-wrapper .logo:after {
  content: "";
  position: absolute;
  border-radius: 10px;
  top: 100%;
  width: calc(100% - 20px);
  height: 4px;
  left: 10px;
  border-width: 4px;
  background: linear-gradient(to right, #000000, #bf1e2e);
}
.nav-brand-wrapper i {
  color: var(--icon-gray-color);
  font-size: 18px;
}
.nav-brand-wrapper a.navbar-brand {
  font-size: 18px;
  padding-left: 10px;
}
.nav-brand-wrapper a.navbar-brand:hover {
  color: var(--icon-gray-color);
}
.ml-left-desktop {
  margin-left: 10px;
}
/*  */
.registerFormWrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 15px;
}
.pre-register-comp {
  max-width: 500px;
  background-color: #ffffff;
  padding: 35px 30px;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 100%;
  position: relative;
}
.pre-register-comp h3 {
  font-weight: 600;
}
.pre-register-comp .logo-width {
  width: 200px;
}
.pre-register-comp input.form-control {
  font-size: 14px;
  color: var(--color);
  background-image: none;
  border: 1px solid rgb(196, 196, 196);
  padding-inline: 1rem;
  height: 40px;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}
.pre-register-comp input::placeholder {
  color: rgb(130, 130, 130);
}
.pre-register-comp label {
  font-size: 14px;
  font-weight: 400;
}
.pre-register-comp input:active,
.pre-register-comp input:focus {
  border-color: rgb(168, 38, 50) !important;
}
.pre-register-comp p {
  color: rgb(130, 130, 130);
  font-size: 12.96px;
  font-weight: 400;
}
.pre-register-comp a {
  color: rgb(168, 38, 50);
  font-weight: 500;
}
.pre-register-comp .form-group-wrapper .radioBox-wrapper {
  display: inline-block;
  min-width: 85px;
}
.pre-register-comp .form-group-wrapper .PhoneInput {
  width: calc(100% - 100px);
}
.pre-register-comp .form-group-wrapper .PhoneInputInput {
  height: 40px;
  border-radius: 3px;
  border: 1px solid var(--border-color);
}
.pre-register-comp .form-group-wrapper .PhoneInput input {
  height: 40px;
}
.dobInput:read-only {
  background-color: #fff;
}

.registerWrapper .signup-btn {
  width: 10 0%;
  height: 38px;
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
  font-family: var(--amplify-font-family);
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-image: none;
  color: #ffffff;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  background-color: #a82632;
  border-width: 1px;
  border: 1px solid #a82632;
  border-radius: 0;
  opacity: 1;
}
.pre-register-comp .signup-btn:disabled {
  opacity: 0.8;
  background-color: #a82632 !important;
  border-color: #a82632;
}
.codeScannerWrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.registered-successful {
  font-family: sans-serif;
  text-align: center;
  width: 450px;
}
.registered-successful h2 {
  font-weight: 500;
  margin: 5px;
  font-size: 16px;
}

/*top search bar*/
.searchbar-wrapper input.form-control {
  border: 1px solid var(--border-color);
  border-radius: 30px;
  padding-right: 30px;
  background-color: transparent;
}
/*top search bar*/
.searchbar-wrapper input::placeholder {
  color: var(--icon-gray-color);
  font-size: 14px;
}
.searchbar-wrapper > i {
  position: absolute;
  top: 14px;
  right: 10px;
  cursor: pointer;
  font-size: 12px;
  color: var(--icon-gray-color);
}

.user-profile-wrapper {
  border-right: 1px solid var(--border-color);
  padding-inline: 15px;
}
.top-user-profile {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 15px;
}
.top-user-profile img {
  width: 50px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.top-user-profile figcaption {
  font-size: 14px;
  color: var(--text-black-color);
  font-weight: 500;
}
.navbar-logout-btn:hover {
  color: var(--icon-gray-color) !important;
}

/*tr:hover {*/
/*  border: 1px solid transparent;*/
/*  border-color: #ab2330;*/
/*  border-width: 1.1px;*/
/*  margin: -1px;*/
/*}*/
.table > :not(:first-child) {
  border-top: 0;
}
.table > thead {
  border-bottom: 2px solid var(--border-color);
}
.table tbody tr {
  border-bottom: 1px solid var(--border-color);
}
.table tbody tr:last-child {
  border-bottom: 1px solid transparent;
}
.nested-row:hover {
  background-color: transparent;
  border: none;
  border-width: 0;
  margin: 0;
}
.user-table {
  table-layout: fixed;
  width: 100%;
}

/**/
.card-header > h4.card-title {
  color: var(--text-black-color);
}

.ellipsis {
  max-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.fixed-table {
  table-layout: fixed;
}
.btn-outline-danger {
  border-color: red;
  color: red;
}
/* .table > tbody > tr > td.tooltip {
  width: auto;
  overflow: visible;
  z-index: 11;
  background-color: #FFFFFF;
} */

.table > tbody > tr > td.icon {
  width: 45px;
  text-overflow: inherit;
  overflow: visible;
  white-space: normal;
}

.table > thead > tr > th.centered {
  text-align: center !important;
}
.table > thead > tr > th.centered > div {
  justify-content: center !important;
}

.hipaaTextArea {
  width: 100%;
  height: 90%;
}
.popOver-centered {
  text-align: center !important;
}
.hipaaDialogue {
  max-height: 100vh;
  max-width: 100vw;
}
.swatch {
  padding: 5px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
  border: 1px solid var(--border-color);
}
.hipaaBody {
  max-height: 90%;
}

/* .modal {
  overflow-x: visible;
  overflow-y: visible;
} */
.modalHeight {
  min-height: 20vh !important;
}
.programPopOverCard {
  min-width: 500px !important;
  min-height: 10vh !important;
}
.modalInput {
  border-radius: 5px;
  padding: 2px 10px;
  background-color: var(--text-white);
  border: 1px solid var(--border-color);
  min-height: 45px;
}
.dateRangeInput {
  border-radius: 5px;
  padding: 2px 10px;
  /*background-color: #f9f9f9;*/
  color: #212529;
  border: 1px solid var(--border-color);
  width: 100%;
  height: 45px;
  cursor: pointer;
  text-align: center;
}
.dateRangeFilter {
  border-radius: 5px;
  padding: 2px 10px;
  color: #212529;
  border: 1px solid #ddd;
  max-width: calc(100% - 134px);
  width: 100%;

  height: 40px;
}
.accordion-header {
  margin: 0 !important;
}

.modalLineHeaders {
  padding-bottom: 0;
  margin-top: 10px;
  margin-bottom: 0;
  color: var(--icon-gray-color);
}
.css-6j8wv5-Input {
  margin: 0 2px !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.radioHead {
  display: flex;
}
.radioHead > * {
  flex-basis: 100%;
}

.roleSelector {
  margin: 0;
}

.buttonHeader {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  row-gap: 10px;
}

.headerButton {
  margin-right: 10px;
  /*margin-bottom: 5px;*/
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 4.5px;
  padding-top: 4.5px;
  min-width: 130px;
  font-size: 1rem;
  border-radius: 0;
  font-weight: normal;
}
.headerButton.btn-primary {
  background-color: var(--primary-btn-color);
  border-color: var(--primary-btn-color);
  color: var(--text-white);
}
.headerButton.btn-primary:hover,
.headerButton.btn-primary:active,
.headerButton.btn-primary:focus {
  background-color: var(--primary-btn-color);
  box-shadow: none;
}
.headerButton.btn-secondary {
  background-color: var(--text-black-color);
  border-color: var(--text-black-color);
  color: var(--text-white);
}
.headerButton.btn-secondary:hover,
.headerButton.btn-secondary:active,
.headerButton.btn-secondary:focus {
  background-color: var(--text-black-color);
  box-shadow: none;
}
a.downloadTest {
  color: #3472f7;
  text-decoration: none;
}

.filterFields {
  margin-top: 10px;
}

.filterDrop {
  width: 145px;
  margin-right: 10px;
}

.searchInput {
  max-width: 500px;
  padding-left: 10px;
  border-top: none !important;
  border-right: none !important;
}

.searchFilterButton {
  width: 130px;
  margin-right: 10px;
}

.changeRoleSelector {
  width: 175px;
}

.createButtons {
  padding-top: 4.5px;
  padding-bottom: 4.5px;
  padding-right: 8px;
  padding-left: 8px;
}

.modalButtons {
  padding-top: 4.5px;
  padding-bottom: 4.5px;
  padding-right: 8px;
  padding-left: 8px;
}

.headerNav {
  display: flex;
  justify-content: space-between;
}

.toggleButtonGroup {
  width: 100px;
}

.checkboxRow {
  display: flex;
  flex-direction: column;
}

.releaseCheckbox {
  margin-right: 7px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.checkboxLabel {
  margin-top: 10px;
}

.createLabModal {
  min-width: 90vw !important;
  min-height: 90vh !important;
}

.createLabBody {
  display: flex;
  flex-wrap: wrap;
}

.labAddressInput {
  display: flex;
  flex-direction: column;
  width: 97%;
}

.notesInput {
  width: 97%;
}

.labModalInput {
  padding: 2px 10px;
  background-color: #f9f9f9;
  width: 100%;
  border-radius: 5px;
  border: 1px solid var(--border-color);
}

.MessageInput {
  height: 140px;
  padding: 2px 10px;
  background-color: #f9f9f9;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ddd;
}
.MessageInput:focus {
  box-shadow: none;
  outline: none;
}

.adminHomeTable td {
  border: none;
}

.adminHomeTable tr:hover {
  border: none;
}
.pdfView {
  flex: 1;
  width: 100%;
  height: 100%;
}

.pdfContainer {
  height: 700px;
}

.footerText {
  font-size: 9px;
  padding-right: 10px;
}

.truncate {
  width: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-right {
  text-align: right;
}
.pr-0 {
  padding-right: 0;
}
.emailWidth {
  width: 20%;
}
.tdWidth10 {
  width: 10%;
}
.tdWidth12 {
  width: 12%;
}
.tdWidth14 {
  width: 14%;
}
.tdWidth16 {
  width: 16%;
}
.tdWidth18 {
  width: 18%;
}
.tdWidth45 {
  width: 45%;
}
.vaccination-status-name {
  font-size: 12px !important;
  margin-bottom: 5px !important;
  text-transform: uppercase;
  color: #9a9a9a;
  font-weight: 400;
  min-height: 18px;
}
.vaccination-status {
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  min-height: 38px;
  display: inline-block;
}
.form-group-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 15px;
  margin-bottom: 15px;
}
.form-group-wrapper > label {
  flex: 0 0 20%;
  max-width: 20%;
  width: 100%;
  margin-top: 0;
  display: inline-block;
  font-size: 14px;
  color: var(--icon-gray-color);
}
.form-group-wrapper > input {
  flex: 0 0 calc(100% - 20% - 15px);
  max-width: calc(100% - 20% - 15px);
  width: calc(100% - 20% - 15px);
  font-size: 14px;
}
.form-group-wrapper .roleSelector {
  width: 100%;
}
.form-group-wrapper.activeEmploye .roleSelector {
  width: 50%;
}
.form-group-wrapper.activeEmploye .siteSelector {
  width: 50%;
}
.form-group-wrapper.activeEmploye .siteSelector .css-14el2xx-placeholder {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.roleSelector .css-1s2u09g-control,
.siteSelector .css-1s2u09g-control {
  background-color: #f9f9f9;
  border-color: #ddd !important;
}
.roleSelector .css-1s2u09g-control:hover,
.siteSelector .css-1s2u09g-control:hover {
  border-color: #ddd !important;
}
/*.roleSelector .css-qc6sy-singleValue, .siteSelector .css-qc6sy-singleValue  {*/
/*  color: #888888;*/
/*}*/
/*.roleSelector .css-1pahdxg-control .css-qc6sy-singleValue {*/
/*  color: #000000 !important;*/
/*}*/

.createClientsWrapper {
  display: flex;
  column-gap: 30px;
}
.createClientsWrapper .leftSide {
  flex: 0 0 calc(40% - 15px);
  max-width: calc(40% - 15px);
  width: 100%;
  flex-wrap: wrap;
  column-gap: 15px;
}

.createClientsWrapper .rightSide {
  flex: 0 0 calc(60% - 15px);
  max-width: calc(60% - 15px);
  width: 100%;
}

.createClientsWrapper .form-group-wrapper {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  line-height: 1;
  display: block;
}
.createClientsWrapper .first-last-name-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 30px;
}
.createClientsWrapper .first-last-name-wrapper .form-group-wrapper {
  flex: 0 0 calc(50% - 15px);
  max-width: calc(50% - 15px);
}
.createClientsWrapper .form-group-wrapper > label {
  color: var(--icon-gray-color);
  line-height: 1;
  padding-left: 10px;
  margin-bottom: 5px;
}
.createClientsWrapper .form-group-wrapper > label,
.createClientsWrapper .form-group-wrapper > input {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  font-size: 14px;
}
.createClientsWrapper .form-group-wrapper .fa-search {
  position: absolute;
  right: 10px;
  top: 36px;
}
.checkbox-inline + .checkbox-inline,
.radio-inline + .radio-inline {
  margin-top: 0;
  margin-left: 10px;
}
.inputFileWrapper {
  flex: 0 0 120px;
  width: 120px;
  height: 120px;
  border: 1px solid #ddd;
  border-radius: 50%;
}

.inputFileWrapper > input {
  opacity: 0;
  width: 100%;
  height: 100%;
}
.inputFileWrapper > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.positionRelative {
  position: relative;
}
.profileEditImage {
  position: absolute;
  bottom: 10px;
  right: -5px;
  background-color: #f5f5f5;
  width: 30px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
}
.checkBoxWrapper {
  display: flex;
  align-items: center;
}
.checkBoxWrapper span {
  flex: 0 0 60%;
  max-width: 60%;
  width: 100%;
}
.checkBoxWrapper input {
  flex: 0 0 auto;
}
.showsModal .checkBoxWrapper {
  width: 100%;
  column-gap: 15px;
}
.showsModal div .checkBoxWrapper span {
  flex: 0 0 42%;
  max-width: 42%;
  width: 100%;
}
.showsModal div .checkBoxWrapper > div {
  flex: 0 0 auto;
}
:root {
  --amplify-primary-color: rgb(168, 38, 50);
  --amplify-primary-tint: #31465f;
  --amplify-primary-shade: rgb(168, 38, 50);
}
.dashboard-page {
  display: flex;
  justify-content: flex-start;
  column-gap: 15px;
  row-gap: 15px;
  flex-wrap: wrap;
}
.dashboard-tab {
  flex: 0 0 calc(33.33% - 10px);
  max-width: calc(33.33% - 10px);
  width: 100%;
  border: 1px solid #fff;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}
.dashboard-tab h4 {
  font-size: 24px;
  margin-top: 0;
  color: #565656;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  padding: 30px 15px 15px;
}
.dashboard-tab p {
  font-size: 24px;
  color: #a82632;
  margin: 0;
  padding: 0 15px 30px;
}
.labsModalWrapper {
  display: flex;
  column-gap: 15px;
}
.labsModalWrapper .form-group-wrapper {
  flex: 0 0 calc(50% - 7px);
  max-width: calc(50% - 7px);
}
.labsModalWrapper .form-group-wrapper table > tbody {
  display: block;
  max-height: 300px;
  overflow-y: scroll;
}
.labsModalWrapper .form-group-wrapper table thead tr,
.labsModalWrapper .form-group-wrapper table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
#example-collapse-text {
  position: absolute;
  width: 96%;
}

.labelYesNo {
  text-decoration: underline;
  font-weight: bold;
  min-width: 35px;
  margin-right: 8px;
  display: inline-block;
}
.formWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 15px;
  padding-left: 15px;
}
.selectTestWrapper .form-group-wrapper {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
}
.imageWrapper {
  min-height: 150px;
}
.text-grey {
  color: grey !important;
}
.uploadFilesArea {
  margin-top: 36px;
}
.linkedText {
  color: #3472f7 !important;
  cursor: pointer;
}
.tdWidth10 {
  width: 10%;
}
.tdWidth12 {
  width: 12%;
}
.showDisclaimerText {
  margin-left: 5px;
  color: #000 !important;
}
.showDisclaimerText span {
  color: #3472f7;
  cursor: pointer;
}
.scroll-popOver {
  max-height: 400px;
  overflow-y: auto;
}
.filterResetBtn {
  display: flex;
  justify-content: right;
  margin-bottom: -15px;
  margin-top: 10px;
}
.textUnderLine {
  text-decoration: underline;
}
.btn:disabled {
  color: gray;
  /*border-color: gray;*/
  background-color: var(--text-black-color);
}
.btn-warning {
  background-color: rgb(168, 38, 50) !important;
  border-color: rgb(168, 38, 50) !important;
}

.loginWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
}
.login-left-side {
  background-color: #f7f7f8;
  flex: 0 0 50%;
  max-width: 50%;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.input-group.group-wrapper {
  border: 1px solid rgb(196, 196, 196);
  border-radius: 10px;
}
.group-wrapper {
  margin-bottom: 30px;
}
.input-group.group-wrapper input,
.input-group.group-wrapper span {
  border: none;
}

.group-wrapper > span.input-group-text {
  padding: 10px 15px;
  background-color: transparent;
}
.group-wrapper > span.input-group-text i {
  color: var(--icon-gray-color);
}
.option label {
  font-size: 85%;
  margin-bottom: 0;
  text-transform: none !important;
  color: hsl(0, 0%, 20%);
}
.searchList .modal-content {
  border-color: var(--amplify-primary-tint) !important;
}
.searchList .modal-header {
  background-color: var(--amplify-primary-tint) !important;
}
.login-right-side {
  flex: 0 0 50%;
  width: 100%;
  padding-inline: 1rem;
  max-width: 440px;
  margin-inline: auto;
}
.login-right-side .tag-line {
  font-size: 14px;
  font-weight: normal;
  color: var(--light-gray-color);
}
.loginWrapper h3 {
  font-weight: 600;
}
.loginWrapper .logo-width {
  width: 300px;
  min-width: 300px;
  max-width: 300px;
}
.loginWrapper input {
  font-size: 14px;
  height: 50px;
  color: var(--color);
  background-image: none;
  border: 1px solid rgb(196, 196, 196);
  padding: 1rem;
  border-radius: 10px;
}
.loginWrapper input::placeholder {
  color: rgb(130, 130, 130);
}
.loginWrapper label {
  font-size: 14px;
  font-weight: 400;
}
.input-group.group-wrapper:hover,
.input-group.group-wrapper:active,
.input-group.group-wrapper:focus {
  border: 1px solid rgb(168, 38, 50) !important;
}
.loginWrapper a.resetPassword {
  text-decoration: none;
  font-weight: 400;
}
.loginWrapper a.resetPassword:hover {
  text-decoration: underline;
  color: rgb(49, 70, 95);
}
.loginWrapper .signin-btn {
  width: 100% !important;
  max-width: 180px;
  height: 50px;
  display: inline-block;
  margin-bottom: 0;
  font-size: 16px;
  font-family: var(--amplify-font-family);
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-image: none;
  color: #ffffff;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  background-color: #a82632;
  border-width: 1px;
  border: 1px solid #a82632;
  border-radius: 0;
  opacity: 1;
}
.loginWrapper .signin-btn:disabled {
  opacity: 0.8;
  background-color: #a82632 !important;
  border-color: #a82632;
}
.uploadFileHippa {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.fileNameHippa {
  border-radius: 3px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid #cccccc;
  padding: 0 10px;
}
.blinkText {
  position: absolute;
  top: 50px;
  right: 50px;
  color: red;
  font-weight: bold;
}
.pdfDownLink {
  text-decoration: none;
  color: #3472f7 !important;
}
.pdfDownLink:hover,
.pdfDownLink:active {
  text-decoration: none !important;
  color: #3472f7 !important;
}
.marginTop0 {
  margin-top: 0px !important;
}

/*custom-checkbox*/
.checkbox-custom {
  opacity: 0;
  position: absolute;
}
.results-sent-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.checkbox-custom,
.checkbox-custom-label {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.checkbox-custom-label,
.radio-custom-label {
  position: relative;
}

.checkbox-custom + .checkbox-custom-label:before {
  content: "";
  background: #fff;
  border: 1px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  line-height: 15px;
  padding: 2px;
  margin-right: 0;
  text-align: center;
}

.checkbox-custom:checked + .checkbox-custom-label:before {
  content: "✓";
  font-family: "FontAwesome";
  background: green;
  color: #fff;
}

.checkbox-custom,
.checkbox-custom-label-yellow {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.checkbox-custom-label-yellow,
.radio-custom-label {
  position: relative;
}

.checkbox-custom + .checkbox-custom-label-yellow:before {
  content: "";
  background: #fff;
  border: 1px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  line-height: 15px;
  padding: 2px;
  margin-right: 0;
  text-align: center;
}

.checkbox-custom:checked + .checkbox-custom-label-yellow:before {
  content: "✓";
  font-family: "FontAwesome";
  background: rgba(255, 251, 0, 0.836);
  color: #000;
}

.EditStyle {
  border: 1px solid #b6b4b4;
  border-radius: 5px;
  padding: 2px 10px;
  min-height: 36px;
}
.radioButton {
  padding: 5px;
}
.form-group-wrapper .scheduleView {
  flex: 0 0 calc(100% - 20% - 15px);
  max-width: calc(100% - 20% - 15px);
}
.form-group-wrapper .scheduleView > input {
  min-height: 45px;
  font-size: 14px;
  background-color: transparent;
  border: 0;
}
.scheduleLabel {
  cursor: pointer;
  color: var(--bs-gray-700);
}
.scheduleModal .modal-content {
  min-height: 700px;
}
.dayLable {
  font-weight: 500;
  cursor: pointer;
}
.scheduleView {
  border: 1px solid var(--border-color);
  border-radius: 5px;
}
.scheduleView .scheduleView-inner .weekDays-wrapper,
.scheduleView .scheduleView-inner .schedule-location {
  background-color: var(--gray-secondary-color);
  height: 47px;
  border-radius: 5px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
/*.scheduleView .scheduleView-inner .weekDays-wrapper {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*}*/
.scheduleView .scheduleView-inner .schedule-location {
  border-radius: 0 5px 0 0;
}
.scheduleView .scheduleView-inner .schedule-test-type {
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.scheduleView .schedules-header {
  border-bottom: 1px solid var(--border-color);
  margin-inline: 0;
  margin-bottom: 25px;
}
.scheduleView .schedules-header > .col-md-3 {
  padding-left: 0;
}
.scheduleView .schedules-header > .col-md-5 {
  padding-right: 0;
}
.sideView > Select {
  cursor: pointer;
  border: 1px solid var(--bs-gray-300);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.listItems:hover {
  background-color: var(--bs-gray-300);
}
.SelectorQuestionGroup {
  margin-top: 30px;
}

.radioBox-wrapper {
  display: flex;
  align-items: center;
  min-width: 50px;
}
.bodyScroll {
  display: block;
  max-height: 300px;
  overflow-y: scroll;
}
textarea.focus-none:focus {
  outline: none;
}
HEAD .modalButtons.ml-1 {
  margin-left: 8px;
}
.personalizationCheckbox input {
  width: 20px;
  height: 20px;
  accent-color: var(--btn-danger);
}
.personalizationCheckbox label {
  font-size: 18px;
  margin-left: 10px;
  display: inline-block;
  position: relative;
  top: -2px;
}
.graphWrapper {
  display: flex;
  justify-content: center;
}
.graphWrapper canvas {
  height: 180px !important;
  width: 180px !important;
}
.selectDateTime > input {
  max-width: 100% !important;
}
/*.assignSchedule {*/
/*  min-width: 250px;*/
/*  max-width: 400px;*/
/*}*/

.testTypeAction {
  cursor: pointer;
}
.testTypeAction:hover {
  color: var(--amplify-primary-color);
}
.bs-popover-start > .popover-arrow {
  right: -34px !important;
}
.popOverSchedule {
  border-color: #31465f;
  width: auto;
  max-width: 700px;
  max-height: 700px;
  overflow-y: auto;
  overflow-x: hidden;
}
.assignSchedule .css-xb97g8:hover {
  background-color: lightblue;
  color: blue;
}
.nav-disabled {
  background: rgba(255, 255, 255, 0.13);
  opacity: 0.4 !important;
}
.barGraph canvas {
  height: 280px !important;
  width: 100% !important;
}
.barGraph {
  height: 100%;
}

.listView {
  margin-left: 2px;
  padding: 0px;
}
.listView li {
  font-size: 20px;
}

.listView li span {
  color: grey;
  text-decoration: underline;
}
.colorBox {
  padding: 0 10px 0 9px;
  margin-right: 10px;
}
.filterSelector {
  max-width: calc(100% - 134px);
  width: 100%;
}
.column-gap-10 {
  column-gap: 10px;
}
.pendingReleaseTable .table.employeeTableWrapper > thead > tr > th:first-child,
.pendingReleaseTable .table.employeeTableWrapper > tbody > tr > td:first-child {
  min-width: 60px;
  width: 60px;
}
.pendingReleaseTable .table.progrmaTableWrapper > thead > tr > th:first-child,
.pendingReleaseTable .table.progrmaTableWrapper > tbody > tr > td:first-child {
  min-width: 80px;
  width: 80px;
}
.pendingReleaseTable .table.programsViewTable > tbody > tr > td:last-child {
  padding-right: 8px !important;
}
.card .table tbody td:last-child .accordion-button {
  font-size: 1.2rem !important;
  font-weight: bold !important;
  color: var(--text-black-color) !important;
}
.accordion-button:not(.collapsed) {
  background-color: var(--main-body-bg) !important;
}
.accordion-button:focus {
  border-color: var(--border-color) !important;
  box-shadow: none;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("assets/img/arrow-up.png") !important;
  transform: rotate(-360deg);
}

.modalPhoneInput {
  width: 100%;
}
.form-group-wrapper .react-date-picker__wrapper {
  border-radius: 5px;
  padding: 2px 10px;
  background-color: var(--text-white);
  border: 1px solid var(--border-color);
  min-height: 45px;
}
.react-date-picker__inputGroup {
  font-size: 14px;
}
.react-date-picker__button svg {
  stroke: var(--text-black);
  width: 16px;
}
.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: var(--text-black) !important;
}

.card .card-body {
  padding: 15px 10px 0;
}
.card .card-body .table {
  margin-bottom: 0;
  border: 1px solid transparent;
}
.overFlow-y-hidden {
  overflow-y: hidden;
}
.pac-container {
  z-index: 1100 !important;
}
.colorRed {
  color: red;
}
.colorGreen {
  color: green;
}
.secondModal {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1056;
}

.updateConfirmation {
  z-index: 1057;
}
.inconsistant-radio-wrapper {
  display: flex;
  column-gap: 10px;
}
.inconsistant-radio-wrapper .form-check {
  display: flex;
  align-items: center;
}
.inconsistant-radio-wrapper .form-check label {
  color: #212529;
  font-size: 14px;
  line-height: 30px;
  position: relative;
  top: 2px;
}
/* .programsViewTable tr td *{
  opacity: 1;
} */
.programSchedules td {
  border: 1px solid gray;
}

.ProgramTestTypeTable tbody tr:hover {
  border: 0 !important;
  border-bottom: 1px solid transparent !important;
}
.testTypeTable {
  width: 480px;
  padding: 0;
}
.testTypeTable thead,
.testTypeTable tbody {
  display: table;
  width: 100%;
}
.testTypeTable th {
  padding: 3px !important;
  color: var(--text-black-color) !important;
  font-weight: bold !important;
}
.testTypeTable td {
  border: 1px solid lightgray;
  padding: 3px !important;
}
.testTypeTable .table > :not(:first-child) {
  border-top: 0;
}
.testTypeTable thead tr {
  border: 1px solid lightgray;
  border-bottom: 1px solid transparent;
}
.testTypeTable thead tr:hover {
  border: 1px solid lightgray;
  border-bottom: 1px solid transparent;
}
.subTableRow:hover {
  background-color: #fff !important;
  border: 1px solid transparent;
  border-color: transparent !important ;
}
.expended-table-wrapper {
  padding-right: 10px !important;
  padding: 10px;
  padding-left: 10px !important;
}
.striped-table-card {
  margin: 0;
}
.expandedTable tbody tr:nth-child(odd) {
  background-color: beige !important;
}
.historyViewModal .modal-content {
  height: 777px;
}
.expandedTable tbody tr:nth-child(even):hover {
  background-color: beige !important;
}
.column-gap-15 {
  column-gap: 15px;
}
.assignProgram-modal-wrapper > label,
.assignProgram-inner > label {
  flex: 0 0 20%;
  max-width: 20%;
  width: 100%;
}
.assignProgram-inner > form {
  flex: 0 0 80%;
  max-width: 80%;
  width: 100%;
}
.assignProgram-inner .question-wrapper {
  flex: 0 0 20%;
  max-width: 20%;
  width: 100%;
}
.assignProgram-inner .questionGroup-wrapper {
  flex: 0 0 80%;
  max-width: 80%;
  width: 100%;
  column-gap: 15px;
}
.assignProgram-inner .questionGroup-wrapper > div {
  flex: 0 0 calc(50% - 7.5px);
  max-width: calc(50% - 7.5px);
  width: 100%;
}
.w-30 {
  width: 30%;
}
.ml-auto {
  margin-left: auto;
}
.message-label {
  position: relative;
  top: 45px;
}
.inputFieldMessage .css-1s2u09g-control {
  border-bottom: 0;
}
.programColor {
  display: inline-block;
  vertical-align: initial;
}
.programSchedules td {
  width: 175px !important;
}

.codeScannerWrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.registered-successful {
  font-family: sans-serif;
  text-align: center;
  width: 100%;
}
.mainLogo-wrapper {
  max-width: 250px;
  margin: auto;
}
.qrWrapper {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: relative;
  padding-inline: 10px;
  border-radius: 10px;
  border: 1px solid lightgray;
}
.registered-successful h2 {
  font-weight: 500;
  margin: 5px;
  font-size: 16px;
}
.card-name {
  padding-bottom: 45px;
}
.registered-inner {
  padding: 15px 0;
}
.userIcon {
  position: absolute;
  top: -45px;
  left: 50%;
  transform: translateX(-50%);
  width: 90px;
  height: 90px;
  border-radius: 50%;
}
.qrWrapper .h4 {
  padding-top: 40px;
  margin-bottom: 0;
}
.qrWrapper .h4 > h4 {
  font-size: 16px;
}
.qrCode {
  width: 185px !important;
  height: 185px !important;
}

.createClientsWrapper .printSide {
  display: none;
  flex: 0 0 100%;
  max-width: 100%;
  /*flex: 0 0 520px;*/
  /*max-width: 520px;*/
  width: 100%;
  padding: 15px;
  background-color: #fff;
}
.newListingIcons {
  display: inline-block;
  margin-right: 10px;
  width: 30px;
  height: 30px;
}

.login-right-side .modalPhoneInput.PhoneInput {
  border: 1px solid var(--border-color);
  padding: 0 0 0 5px;
  border-radius: 10px;
}
.login-right-side .modalPhoneInput.PhoneInput:hover,
.login-right-side .modalPhoneInput.PhoneInput:active,
.login-right-side .modalPhoneInput.PhoneInput:focus {
  border-color: #a82632;
}
.login-right-side .modalPhoneInput.PhoneInput input {
  border: 0;
}
.form-group-wrapper.phoneInput input {
  border-radius: 5px;
  border: 1px solid var(--border-color);
}
.form-group-wrapper .react-date-picker__wrapper {
  border: 1px solid var(--border-color);
  border-radius: 5px;
}
/*react tabs styling*/
.system-settings-wrapper .react-tabs__tab-list {
  border-bottom: 0;
  margin-bottom: 0;
}
.system-settings-wrapper .react-tabs__tab--selected {
  border-radius: 10px 10px 0 0;
}
.system-settings-wrapper .react-tabs__tab {
  padding: 6px 12px 16px;
  font-size: 14px;
}

/*country phone input selection*/
.createClientsWrapper .form-group-wrapper .modalPhoneInput.PhoneInput {
  border: 1px solid var(--border-color);
  min-height: 45px;
  background-color: var(--text-white);
  border-radius: 5px;
  padding-inline: 15px;
  position: relative;
}
.createClientsWrapper .form-group-wrapper .modalPhoneInput.PhoneInput input {
  border: 0;
  font-size: 14px;
  min-height: 100%;
}
/*select dropdown*/
.css-1s2u09g-control,
.css-1pahdxg-control {
  min-height: 45px !important;
  box-shadow: none !important;
  border-color: var(--border-color) !important;
}
.css-1s2u09g-control:hover,
.css-1s2u09g-control:active,
.css-1s2u09g-control:focus,
.css-1pahdxg-control:focus {
  box-shadow: none !important;
  outline: none !important;
  border-color: var(--border-color) !important;
}
.css-1okebmr-indicatorSeparator {
  display: none;
}
.css-tj5bde-Svg {
  color: var(--text-black);
  width: 18px;
}

.pre-register-comp .css-1s2u09g-control {
  min-height: 40px !important;
}
.pre-register-comp .css-qc6sy-singleValue {
  color: var(--icon-gray-color);
}

/*radio button style*/

.radio-button-wrapper input[type="radio"] {
  display: none;
}

.radio-button-wrapper label {
  cursor: pointer;
  position: relative;
  font-size: 1rem;
  padding-left: 25px;
}

.radio-button-wrapper label::before {
  content: "";
  position: absolute;
  width: 1rem;
  height: 1rem;
  background-color: var(--text-white);
  border: 1px solid var(--border-color);
  border-radius: 3px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transition: all 500ms ease;
}
.radio-button-wrapper input[type="radio"]:checked + label::before {
  border-color: var(--btn-danger);
  background-color: var(--btn-danger);
  transition: all 0.5s ease-in;
}
.itemRowWrapper .css-14el2xx-placeholder {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.registerFormWrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 15px;
}
.preLoginWrapper {
  max-width: 560px;
  background-color: #ffffff;
  padding: 35px 30px;
  border-radius: 15px;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  width: 100%;
  height: auto;
}
.preLoginWrapper .logo-width {
  width: 250px;
  min-width: 250px;
  max-width: 250px;
}
.preLoginWrapper input {
  font-size: 14px;
  height: 50px;
  color: var(--color);
  background-image: none;
  border: 1px solid rgb(196, 196, 196);
  padding: 1rem;
  border-radius: 10px;
}
.preLoginWrapper input::placeholder {
  color: rgb(130, 130, 130);
}
.preLoginWrapper label {
  font-size: 14px;
  font-weight: 400;
}
.codeScannerPhoneInput {
  min-height: auto !important;
}
.tcMatrixPlusIcon {
  font-size: 30px;
  color: var(--icon-gray-color);
  -webkit-text-stroke: 1px white;
}
.tcMatrixPlusIcon:hover {
  color: var(--btn-danger);
}

.activeProgram {
  color: var(--btn-danger);
}
.tcMatrixRows .departmentName,
.matrixRowsProgram,
.programName {
  color: var(--light-gray-color);
  display: inline-block;
  font-weight: normal;
}

.tcMatrixWrapper table th,
.tcMatrixWrapper table td {
  border-collapse: collapse;
  border: 1px solid var(--light-gray-color);
}
.tcMatrixRows,
.matrixRowsProgram {
  border-bottom: 0 !important;
}
.tcMatrixRows > td {
  box-shadow: rgb(0 0 0 / 10%) 0 4px 12px;
  display: block;
  width: 100% !important;
  max-width: calc(100% - 20px) !important;
  margin-left: 10px;
  margin-bottom: 15px;
  margin-right: 10px;
  border-radius: 10px;
  border-bottom: 1px solid transparent;
}
.tcMatrixWrapper .tcMatrixViewCard {
  border: 0 !important;
}
.matrixRowsProgram {
  margin-bottom: 15px;
}
/* .activeDepartment {
  background-color: rgb(234, 227, 227) !important;
} */
.matrixRowsProgram > td {
  box-shadow: rgb(0 0 0 / 10%) 0 4px 12px;
  display: block;
  width: 100% !important;
  max-width: calc(100% - 20px) !important;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px;
  border-bottom: 1px solid transparent;
}
.matrixListData,
.matrixListData > td {
  border-bottom: 0 !important;
}

/******************************************* breakPoints ******************************/

@media only screen and (min-width: 1600px) {
  .filterDrop {
    width: 150px;
  }
}

@media only screen and (min-width: 1366px) {
  .desktop-noScroll .table-responsive {
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 1199px) {
  .pendingReleaseTable .user-table {
    table-layout: auto;
  }
  .table > thead > tr > th,
  .table > tbody > tr > td {
    min-width: 9rem !important;
    width: 100% !important;
    max-width: 9rem !important;
  }
}

@media only screen and (max-width: 992px) {
  .createClientsWrapper .form-group-wrapper {
    flex: calc(100% - 0px);
    max-width: calc(100% - 0px);
  }
  .showsModal div .checkBoxWrapper span {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .labsModalWrapper {
    flex-direction: column;
  }
  .labsModalWrapper .form-group-wrapper {
    flex: calc(100% - 0px);
    max-width: calc(100% - 0px);
  }
  .uploadFilesArea {
    margin-top: 15px;
  }
  .createClientsWrapper {
    display: block;
  }
  .createClientsWrapper .leftSide,
  .createClientsWrapper .rightSide {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .createClientsWrapper .leftSide .modalPhoneInput.PhoneInput {
    flex: 0 0 calc(100% - 0px);
    max-width: calc(100% - 0px);
    width: 100%;
  }
  .createClientsWrapper .first-last-name-wrapper {
    display: block;
  }
  .createClientsWrapper .first-last-name-wrapper .form-group-wrapper {
    flex: calc(100% - 0px);
    max-width: calc(100% - 0px);
  }
  .form-group-wrapper {
    display: block;
  }
  .form-group-wrapper > label {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .form-group-wrapper > input {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .scheduleView {
    min-width: 100% !important;
    /*overflow-x: auto;*/
    width: 100%;
  }

  .scheduleView .row > .col-md-3 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .scheduleView .row > .col-md-5 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .scheduleView .row > .col-md-4 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}

@media (max-width: 991px) {
  .changeRoleSelector {
    width: 100%;
  }
  .system-settings-wrapper .headerButton.btn-secondary {
    margin-bottom: 10px;
  }
  .scheduleView .row > .col-md-4.radioButtonSec {
    flex: 0 0 50%;
    max-width: 50%;
    width: 100%;
  }
}

@media only screen and (min-width: 577px) and (max-width: 992px) {
  .itemRowWrapper {
    overflow-x: auto;
  }
}
@media only screen and (max-width: 767px) {
  .table > thead > tr > th,
  .table > tbody > tr > td {
    min-width: 120px !important;
    width: 100% !important;
    max-width: 120px !important;
  }
}
@media only screen and (max-width: 576px) {
  .loginWrapper {
    display: block;
    background-color: #f7f7f8;
  }
  .login-left-side {
    flex: 0 0 100%;
    max-width: 100%;
    background-color: transparent;
  }
  .login-left-side {
    min-height: 40vh;
  }
  .login-right-side {
    min-height: 60vh;
    flex: 0 0 80%;
    max-width: 80%;
  }
  .scheduleView .schedules-header > .col-md-3,
  .scheduleView .schedules-header > .col-md-4,
  .scheduleView .schedules-header > .col-md-5 {
    padding-right: 0;
    padding-left: 0;
  }
  .scheduleView .schedules-header > .col-md-5 {
    flex: 0 0 auto;
    max-width: 100%;
    width: 100%;
  }
  .scheduleView .scheduleView-inner .schedule-location {
    border-radius: 0 0;
  }
  .scheduleView .row > .col-md-3,
  .scheduleView .row > .col-md-4 {
    flex: 0 0 auto;
    max-width: 100%;
    width: 100%;
  }
  .ml-left-desktop {
    margin-left: 0;
  }
  .scheduleView .row > .col-md-4.radioButtonSec {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
  }
  .registerWrapper {
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, 0%);
  }
}

@media only screen and (min-width: 376px) and (max-width: 576px) {
  .scheduleView .row > .col-md-4.addRemoveSec,
  .addRemoveSec {
    position: absolute;
    bottom: 0;
  }
  .scheduleView .row > .col-md-4.addRemoveSec .fa-plus,
  .addRemoveSec .fa-plus,
  .addRemoveSec .fa-trash {
    position: absolute;
    right: 45px;
    top: -22px;
  }
  .addRemoveSec .fa-trash {
    right: 70px;
  }
  .addRemoveSec.trashIconTestType .fa-trash {
    right: 15px;
    position: absolute;
    top: -22px;
  }
}

@media only screen and (max-width: 425px) {
  .login-right-side {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .scheduleView .row > .col-md-4.radioButtonSec,
  .scheduleView .row > .col-md-4.addRemoveSec {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
  }
  .textAreaMessageInput .css-b62m3t-container {
    width: 100%;
  }
}
@media only screen and (max-width: 374px) {
  .headerButton {
    min-width: 120px;
  }
}

@media print {
  @page {
    margin: 0;
    padding: 0;
  }
  .modal {
    display: none;
    overflow-y: hidden !important;
  }
  .modal-xl {
    max-width: 100% !important;
    width: 100% !important;
    margin: 0 !important;
  }
  .modal .modal-content .modal-body {
    padding-left: 0;
  }
  .createClientsWrapper {
    column-gap: 0;
  }

  *,
  body * {
    visibility: hidden;
  }
  .modal-header {
    display: none;
  }
  .createClientsWrapper .leftSide,
  .createClientsWrapper .rightSide {
    display: none;
  }
  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }

  #printableArea,
  #printableArea * {
    visibility: visible;
  }

  .createClientsWrapper .printSide {
    display: block;
  }
  #section-to-print,
  .createClientsWrapper .printSide {
    flex: 0 0 100%;
    max-width: 100%;
    /*flex: 0 0 520px;*/
    /*max-width: 520px;*/
    width: 100%;
    position: fixed;
    inset: 0;
    height: 100%;
    /* background-color: red; */
    /* padding: 0 40px; */
  }
  .codeScannerWrapper {
    align-items: center;
  }
  .registered-successful {
    font-family: sans-serif;
    text-align: center;
    width: 450px;
  }
  .registered-successful {
    width: 450px;
  }
  .registered-inner {
    padding: 30px 60px;
  }
  .qrWrapper {
    box-shadow: none;
  }
  .print-btn,
  .modal-footer {
    display: none;
  }
  /*  dtc printer*/
  .printSide .registered-successful {
    width: 280px;
    max-width: 280px;
  }
  .printSide .codeScannerWrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
  .printSide .mainLogo-wrapper {
    width: 100px;
    margin: auto;
  }
  .printSide .mainLogo-wrapper .img-fluid {
    width: 100px;
  }
  .printSide .registered-inner {
    padding: 5px 30px;
    width: 280px;
    max-width: 280px;
    margin: auto;
  }
  .printSide .qrWrapper {
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    position: relative;
    padding-inline: 10px;
    border-radius: 10px;
    border: 1px solid lightgray;
    margin-left: 15px;
    margin-right: 15px;
  }
  .printSide .userIcon {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .printSide .qrWrapper .h4,
  .printSide h4 {
    padding-top: 0;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1.3;
  }
  .printSide .qrWrapper .h4 > h4 {
    font-size: 14px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .printSide .qrCode {
    width: 80px !important;
    height: 80px !important;
  }
  .printSide .card-name {
    padding-bottom: 30px;
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .qrCodeWrapper.printQrCode {
    max-width: 100px;
    margin-inline: auto;
    padding-block: 5px;
    display: block;
  }
  .printQrCode #react-qrcode-logo {
    width: 75px !important;
    height: 75px !important;
  }
  .printQrCode .qrSubdiv {
    font-size: 10px !important;
    display: block;
  }
}

.imagePathField > img {
  width: 98%;
  max-height: 300px;
}
.uploadImage {
  cursor: pointer;
  font-size: 14px;
  left: 5px;
  margin-top: 5px;
  background-color: #a82632;
  width: 100px;
  height: 30px;
  border-radius: 3px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.fa-star {
  color: #fcc603;
}

.qrCodeWrapper {
  border-radius: 10px;
  border: 1px solid lightgray;
  padding-top: 20px;
  padding-bottom: 20px;
}
.qrSubdiv {
  border: none;
}
.printQrCode {
  display: none;
}
